<template>
  <div class="admin-recurrent-page custom-container">
    <div class="recurrent-table-container">
      <div class="header-wrapper">
        <h4 class="title">
          Wiederkehrende Termine
        </h4>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Tippe um zu filtrieren"
            debounce="200"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Klar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="table-box">
        <b-table
          :items="items"
          :fields="fields"
          :filter="filter"
          :filter-function="recurrentFilterTable"
          :busy="isLoading"
          bordered
          responsive
          striped
          hover
          show-empty
        >
          <template v-slot:head(function)>
            <b-btn
              variant="tab-orange"
              size="sm"
              @click="onCreateRecurrent"
            >
              Neu
            </b-btn>
          </template>
          <template #table-busy>
            <div class="text-center text-tab-orange my-4">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template v-slot:empty>
            <div class="empty-slot">
              <h4>KEINE EREIGNISSE</h4>
            </div>
          </template>
          <template v-slot:cell(day)="{ item }">
            {{ item.date | germanWeekdayName }}
          </template>
          <template v-slot:cell(weekNames)="{ value }">
            <span
              v-for="(day, index) in value.split(',')"
              :key="`daytable-${day}${index}`"
            >
              <template v-if="index === value.split(',').length - 1">
                {{ recurringDayOptionsOnlyText[parseInt(day)].substring(0, 2) }}
              </template>
              <template v-else>
                {{ recurringDayOptionsOnlyText[parseInt(day)].substring(0, 2) }},
              </template>
            </span>
          </template>
          <template v-slot:cell(dateFrom)="{ value }">
            {{ value | germanDate }}
          </template>
          <template v-slot:cell(dateTo)="{ value }">
            {{ value | germanDate }}
          </template>
          <template v-slot:cell(function)="{ item }">
            <div class="function-td">
              <button
                @click="onClickDetail(item)"
              >
                <icon-info />
              </button>
              <button @click="onEdit(item)">
                <icon-pencil />
              </button>
              <button @click="onDelete(item.discriminator)">
                <icon-trash />
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <base-modal
      id="admin-add-recurrent-modal"
      title="Neuer wiederkehrender Termin"
      custom-class="admin-add-recurrent-modal"
      size="xl"
      admin
      no-close-on-backdrop
    >
      <b-row class="add-recurrent-form">
        <b-col :md="addRecurrentInputForm.recurringDays.col">
          <b-form-group
            :label="addRecurrentInputForm.recurringDays.label"
            :label-for="`input-${addRecurrentInputForm.recurringDays.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.recurringDays.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.recurringDays.key]"
          >
            <b-form-checkbox-group
              :id="`input-${addRecurrentInputForm.recurringDays.key}`"
              v-model="addRecurrentInputForm.recurringDays.value"
              :options="recurringDayOptions"
              class="day-list"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.recurringDays.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.type.col">
          <b-form-group
            :label="addRecurrentInputForm.type.label"
            :label-for="`input-${addRecurrentInputForm.type.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.type.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.type.key]"
          >
            <b-form-select
              :id="`input-${addRecurrentInputForm.type.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.type.key].value"
              :options="typeOptions"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.type.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.timeFrom.col">
          <b-form-group
            :label="addRecurrentInputForm.timeFrom.label"
            :label-for="`input-${addRecurrentInputForm.timeFrom.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.timeFrom.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.timeFrom.key]"
          >
            <vue-timepicker
              :id="`input-${addRecurrentInputForm.timeFrom.key}`"
              ref="timeFromRef"
              v-model="addRecurrentInputForm[addRecurrentInputForm.timeFrom.key].value"
              :hour-range="recurrentTimeFromHourRange"
              :minute-interval="30"
              hide-disabled-hours
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.timeFrom.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.timeTo.col">
          <b-form-group
            :label="addRecurrentInputForm.timeTo.label"
            :label-for="`input-${addRecurrentInputForm.timeTo.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.timeTo.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.timeTo.key]"
          >
            <vue-timepicker
              :id="`input-${addRecurrentInputForm.timeTo.key}`"
              ref="timeToRef"
              v-model="addRecurrentInputForm[addRecurrentInputForm.timeTo.key].value"
              :hour-range="recurrentTimeToHourRange"
              :minute-interval="recurrentTimeToMinuteInterval"
              :minute-range="recurrentTimeToMinuteRange"
              :disabled="isTimeFromIncomplete"
              hide-disabled-hours
              hide-disabled-minutes
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.timeTo.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="addRecurrentInputForm.type.value === 'News'"
          md="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="input-GanzerTag"
              v-model="is24Hour"
              @input="onClick24Hour"
            >
              Ganzer Tag
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.dateFrom.col">
          <b-form-group
            :label="addRecurrentInputForm.dateFrom.label"
            :label-for="`input-${addRecurrentInputForm.dateFrom.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.dateFrom.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.dateFrom.key]"
          >
            <b-form-datepicker
              :id="`input-${addRecurrentInputForm.dateFrom.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.dateFrom.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Kein Datum ausgewählt"
              locale="de"
              @input="onDateFromInput"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.dateTo.col">
          <b-form-group
            :label="addRecurrentInputForm.dateTo.label"
            :label-for="`input-${addRecurrentInputForm.dateTo.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.dateTo.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.dateTo.key]"
          >
            <b-form-datepicker
              :id="`input-${addRecurrentInputForm.dateTo.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.dateTo.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="minDateTo"
              :disabled="addRecurrentInputForm[addRecurrentInputForm.dateFrom.key].value === ''"
              placeholder="Kein Datum ausgewählt"
              locale="de"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.dateTo.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.information.col">
          <b-form-group
            :label="addRecurrentInputForm.information.label"
            :label-for="`input-${addRecurrentInputForm.information.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.information.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.information.key]"
          >
            <b-form-textarea
              :id="`input-${addRecurrentInputForm.information.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.information.key].value"
              rows="5"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.information.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="mb-2"
        >
          <h5>Kundeninformationen <small>(Optional)</small></h5>
        </b-col>
        <b-col :md="addRecurrentInputForm.name.col">
          <b-form-group
            :label="addRecurrentInputForm.name.label"
            :label-for="`input-${addRecurrentInputForm.name.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.name.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.name.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.name.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.name.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.name.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.name.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.company.col">
          <b-form-group
            :label="addRecurrentInputForm.company.label"
            :label-for="`input-${addRecurrentInputForm.company.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.company.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.company.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.company.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.company.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.company.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.company.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.telephone.col">
          <b-form-group
            :label="addRecurrentInputForm.telephone.label"
            :label-for="`input-${addRecurrentInputForm.telephone.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.telephone.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.telephone.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.telephone.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.telephone.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.telephone.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.telephone.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.email.col">
          <b-form-group
            :label="addRecurrentInputForm.email.label"
            :label-for="`input-${addRecurrentInputForm.email.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.email.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.email.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.email.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.email.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.email.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.email.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitAddRecurrent"
      >
        EINREICHEN
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-edit-recurrent-modal"
      title="Edit wiederkehrender Termin"
      custom-class="admin-add-recurrent-modal"
      size="xl"
      admin
      no-close-on-backdrop
    >
      <b-row class="add-recurrent-form">
        <b-col :md="addRecurrentInputForm.recurringDays.col">
          <b-form-group
            :label="addRecurrentInputForm.recurringDays.label"
            :label-for="`input-${addRecurrentInputForm.recurringDays.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.recurringDays.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.recurringDays.key]"
          >
            <b-form-checkbox-group
              :id="`input-${addRecurrentInputForm.recurringDays.key}`"
              v-model="addRecurrentInputForm.recurringDays.value"
              :options="recurringDayOptions"
              class="day-list"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.recurringDays.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.type.col">
          <b-form-group
            :label="addRecurrentInputForm.type.label"
            :label-for="`input-${addRecurrentInputForm.type.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.type.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.type.key]"
          >
            <b-form-select
              :id="`input-${addRecurrentInputForm.type.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.type.key].value"
              :options="typeOptions"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.type.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.timeFrom.col">
          <b-form-group
            :label="addRecurrentInputForm.timeFrom.label"
            :label-for="`input-${addRecurrentInputForm.timeFrom.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.timeFrom.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.timeFrom.key]"
          >
            <vue-timepicker
              :id="`input-${addRecurrentInputForm.timeFrom.key}`"
              ref="timeFromRef"
              v-model="addRecurrentInputForm[addRecurrentInputForm.timeFrom.key].value"
              :hour-range="recurrentTimeFromHourRange"
              :minute-interval="30"
              hide-disabled-hours
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.timeFrom.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.timeTo.col">
          <b-form-group
            :label="addRecurrentInputForm.timeTo.label"
            :label-for="`input-${addRecurrentInputForm.timeTo.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.timeTo.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.timeTo.key]"
          >
            <vue-timepicker
              :id="`input-${addRecurrentInputForm.timeTo.key}`"
              ref="timeToRef"
              v-model="addRecurrentInputForm[addRecurrentInputForm.timeTo.key].value"
              :hour-range="recurrentTimeToHourRange"
              :minute-interval="recurrentTimeToMinuteInterval"
              :minute-range="recurrentTimeToMinuteRange"
              :disabled="isTimeFromIncomplete"
              hide-disabled-hours
              hide-disabled-minutes
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.timeTo.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="addRecurrentInputForm.type.value === 'News'"
          md="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="input-GanzerTag"
              v-model="is24Hour"
              @input="onClick24Hour"
            >
              Ganzer Tag
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.dateFrom.col">
          <b-form-group
            :label="addRecurrentInputForm.dateFrom.label"
            :label-for="`input-${addRecurrentInputForm.dateFrom.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.dateFrom.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.dateFrom.key]"
          >
            <b-form-datepicker
              :id="`input-${addRecurrentInputForm.dateFrom.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.dateFrom.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Kein Datum ausgewählt"
              locale="de"
              @input="onDateFromInput"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.dateTo.col">
          <b-form-group
            :label="addRecurrentInputForm.dateTo.label"
            :label-for="`input-${addRecurrentInputForm.dateTo.key}`"
            label-cols-sm="3"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.dateTo.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.dateTo.key]"
          >
            <b-form-datepicker
              :id="`input-${addRecurrentInputForm.dateTo.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.dateTo.key].value"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :min="minDateTo"
              :disabled="addRecurrentInputForm[addRecurrentInputForm.dateFrom.key].value === ''"
              placeholder="Kein Datum ausgewählt"
              locale="de"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.dateTo.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.information.col">
          <b-form-group
            :label="addRecurrentInputForm.information.label"
            :label-for="`input-${addRecurrentInputForm.information.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.information.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.information.key]"
          >
            <b-form-textarea
              :id="`input-${addRecurrentInputForm.information.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.information.key].value"
              rows="5"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.information.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="mb-2"
        >
          <h5>Kundeninformationen <small>(Optional)</small></h5>
        </b-col>
        <b-col :md="addRecurrentInputForm.name.col">
          <b-form-group
            :label="addRecurrentInputForm.name.label"
            :label-for="`input-${addRecurrentInputForm.name.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.name.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.name.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.name.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.name.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.name.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.name.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.company.col">
          <b-form-group
            :label="addRecurrentInputForm.company.label"
            :label-for="`input-${addRecurrentInputForm.company.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.company.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.company.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.company.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.company.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.company.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.company.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.telephone.col">
          <b-form-group
            :label="addRecurrentInputForm.telephone.label"
            :label-for="`input-${addRecurrentInputForm.telephone.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.telephone.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.telephone.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.telephone.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.telephone.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.telephone.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.telephone.key] = null"
            />
          </b-form-group>
        </b-col>
        <b-col :md="addRecurrentInputForm.email.col">
          <b-form-group
            :label="addRecurrentInputForm.email.label"
            :label-for="`input-${addRecurrentInputForm.email.key}`"
            :state="isErrorAddRecurrentForm[addRecurrentInputForm.email.key]"
            :invalid-feedback="errorMsgAddRecurrentForm[addRecurrentInputForm.email.key]"
          >
            <b-form-input
              :id="`input-${addRecurrentInputForm.email.key}`"
              v-model="addRecurrentInputForm[addRecurrentInputForm.email.key].value"
              :type="addRecurrentInputForm[addRecurrentInputForm.email.key].type"
              @input="isErrorAddRecurrentForm[addRecurrentInputForm.email.key] = null"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitEditRecurrent"
      >
        EDIT
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-duplicate-event-modal"
      title="Please handle all duplicate events before submitting"
      custom-class="admin-duplicate-event-modal"
      size="lg"
      admin
      no-close-on-backdrop
      @hide="onCancelDuplicateEvent"
    >
      <div
        v-if="duplicateEvents.length > 1"
        class="duplicate-event-functions"
      >
        <b-btn
          size="sm"
          class="item"
          @click="onReplaceAll"
        >
          Replace All
        </b-btn>
        <b-btn
          size="sm"
          class="item"
          @click="onSkipAll"
        >
          Skip All
        </b-btn>
      </div>
      <div class="duplicate-event-list">
        <div
          v-for="(event, index) in duplicateEvents"
          :key="`duplicate-${event.identification}${index}`"
          class="duplicate-event-item"
        >
          <p class="mb-1">
            <strong>{{ event.activityName }}</strong>
          </p>
          <p>
            {{ `Booker: ${event.name || 'Admin'}` }}
          </p>
          <p>
            <span class="mr-1">{{ event.bookingDay | germanWeekdayName }}, </span>
            <span>{{ event.bookingDay | germanDate }}</span>
          </p>
          <p>
            <strong>Time:</strong>
            {{ `${event.timeFrom} - ${event.timeTo}` }}
          </p>
          <p>
            <strong>Your new selected:</strong>
            <span class="text-danger ml-2">{{ addRecurrentInputForm.type.value }}</span>
            <span class="text-danger ml-2">{{ `${addRecurrentInputForm.timeFrom.value} - ${addRecurrentInputForm.timeTo.value}` }}</span>
          </p>
          <template v-if="event.information">
            <p>Information:</p>
            <p v-html="event.information" />
          </template>
          <div class="functions">
            <b-btn
              variant="tab-orange"
              size="sm"
              class="mb-2"
              @click="handleReplace(event)"
            >
              Replace
            </b-btn>
            <b-btn
              size="sm"
              @click="handleLeave(event)"
            >
              Skip
            </b-btn>
          </div>
        </div>
      </div>
      <b-btn
        :disabled="duplicateEvents.length !== 0"
        variant="tab-orange"
        class="mt-1"
        @click="onSubmitAfterHandleDuplicate"
      >
        EINREICHEN
      </b-btn>
    </base-modal>

    <base-modal
      id="admin-duplicate-event-readonly-modal"
      :title="editId !== 0 ? 'Edit recurrent error' : 'Add recurrent error'"
      custom-class="admin-duplicate-event-modal"
      size="lg"
      admin
      error
    >
      <div class="duplicate-event-list">
        <template v-if="duplicateEventErrorMsg">
          <h5 class="mb-4 text-danger">
            {{ duplicateEventErrorMsg.information }}
          </h5>
          <p
            v-for="(detail, index) in duplicateEventErrorMsg.details"
            :key="`duplicateEventError${index}`"
            class="mb-2 text-danger"
          >
            {{ detail }}
          </p>
        </template>
        <div
          v-for="(event, index) in duplicateEvents"
          :key="`duplicate-${event.identification}${index}`"
          class="duplicate-event-item"
        >
          <p class="mb-1">
            <strong>{{ event.activityName }}</strong>
          </p>
          <p>
            {{ `Booker: ${event.name || 'Admin'}` }}
          </p>
          <p>
            <span class="mr-1">{{ event.bookingDay | germanWeekdayName }}, </span>
            <span>{{ event.bookingDay | germanDate }}</span>
          </p>
          <p>
            <strong>Existed event time:</strong>
            {{ `${event.timeFrom} - ${event.timeTo}` }}
          </p>
          <p>
            <strong>Your new selected time:</strong>
            <span class="text-danger ml-2">{{ `${addRecurrentInputForm.timeFrom.value} - ${addRecurrentInputForm.timeTo.value}` }}</span>
          </p>
          <template v-if="event.information">
            <p>Information:</p>
            <p v-html="event.information" />
          </template>
        </div>
      </div>
    </base-modal>

    <base-modal
      id="admin-recurrent-detail-modal"
      title="Wiederkehrend-Detail"
      custom-class="admin-recurrent-detail-modal"
      admin
      size="xl"
    >
      <template v-if="selectRecurrentDetail.weekNames">
        <div class="detail">
          <h4 class="text-center mb-4">
            Information:
          </h4>
          <div class="item-row mb-3">
            <p class="label">
              Name:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.name }}
            </p>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Kundenname:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.customer }}
            </p>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Telefon:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.telephone }}
            </p>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Email:
            </p>
            <a
              :href="`mailto:${selectRecurrentDetail.email}`"
              class="value"
            >{{ selectRecurrentDetail.email }}</a>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Terminart:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.activityType }}
            </p>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Startdatum:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.dateFrom | germanDate }}
            </p>
          </div>
          <div class="item-row mb-3">
            <p class="label">
              Enddatum:
            </p>
            <p class="value">
              {{ selectRecurrentDetail.dateTo | germanDate }}
            </p>
          </div>
        </div>
        <div class="calendar">
          <h4 class="text-center mb-3">
            Recurrent Detail
          </h4>
          <div class="text-center">
            <v-date-picker
              v-model="selectRecurrentDetail.details[0]"
              :allowed-dates="allowedDatesRecurrentDetail"
              :min="selectRecurrentDetail.dateFrom"
              :max="selectRecurrentDetail.dateTo"
              locale="de"
              color="#F09009"
              flat
              readonly
            />
          </div>
        </div>
        <div class="information">
          <div class="item-row">
            <p class="label">
              Information:
            </p>
            <p
              class="value"
              v-html="selectRecurrentDetail.information"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/pages/admin-recurrent.scss";
</style>

<script>
import { germanDate, germanWeekdayName, germanDateForSearch } from '@/helpers/filters/germanDate';
import { AdminInformation } from '@/services';
import IconPencil from '@/components/icons/IconPencil';
import IconInfo from '@/components/icons/IconInfo';
import IconTrash from '@/components/icons/IconTrash';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

require('@/plugins/datejs/date-de-DE');

import { mapGetters, mapState } from 'vuex';
import { loader } from '@/plugins/loader';

import {
  recurrentTableFields,
} from './config';

export default {
  components: {
    IconPencil,
    IconInfo,
    IconTrash,
    VueTimepicker,
  },
  filters: { germanDate, germanWeekdayName },
  data() {
    const today = Date.today().toString('dd-MM-yyyy');
    return {
      today: today,
      fields: recurrentTableFields,
      items: [],
      filter: '',
      isLoading: false,
      duplicateEvents: [],
      addRecurrentInputForm: {
        recurringDays: {
          value: [],
          col: '6',
          type: 'selectGroup',
          required: true,
          label: 'Wochentag:',
          key: 'recurringDays',
        },
        type: {
          value: '',
          col: '6',
          type: 'select',
          required: true,
          label: 'Terminart:',
          key: 'type',
        },
        dateFrom: {
          value: '',
          col: '6',
          type: 'date',
          required: true,
          label: 'Startdatum:',
          key: 'dateFrom',
        },
        dateTo: {
          value: '',
          col: '6',
          type: 'date',
          required: true,
          label: 'Enddatum:',
          key: 'dateTo',
        },
        timeFrom: {
          value: '',
          col: '6',
          type: 'time',
          required: true,
          label: 'Von:',
          key: 'timeFrom',
        },
        timeTo: {
          value: '',
          col: '6',
          type: 'time',
          required: true,
          label: 'Bis:',
          key: 'timeTo',
        },
        information: {
          value: '',
          col: '12',
          type: 'textarea',
          required: false,
          label: 'Ihre Nachricht',
          key: 'information',
        },
        name: {
          value: '',
          col: '6',
          type: 'text',
          required: false,
          label: 'Name',
          key: 'name',
        },
        telephone: {
          value: '',
          col: '6',
          type: 'number',
          required: false,
          label: 'Telefonnummer',
          key: 'telephone',
        },
        company: {
          value: '',
          col: '6',
          type: 'text',
          required: false,
          label: 'Firma',
          key: 'company',
        },
        email: {
          value: '',
          col: '6',
          type: 'email',
          required: false,
          label: 'Email',
          key: 'email',
        },
      },
      isErrorAddRecurrentForm: {
        recurringDays: null,
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        information: null,
        type: null,
        name: null,
        telephone: null,
        company: null,
        email: null,
      },
      errorMsgAddRecurrentForm: {
        recurringDays: '',
        dateFrom: '',
        dateTo: '',
        timeFrom: '',
        timeTo: '',
        information: '',
        type: '',
        name: '',
        telephone: '',
        company: '',
        email: '',
      },
      recurringDayOptions: [
        { value: 0, text: 'Sonntag' },
        { value: 1, text: 'Montag' },
        { value: 2, text: 'Dienstag' },
        { value: 3, text: 'Mittwoch' },
        { value: 4, text: 'Donnerstag' },
        { value: 5, text: 'Freitag' },
        { value: 6, text: 'Samstag' },
      ],
      editId: 0,
      replaceEvents: [],
      selectRecurrentDetail: {},
      duplicateEventErrorMsg: '',
      is24Hour: false,
    };
  },
  computed: {
    ...mapState('base', ['activities']),
    ...mapGetters('base', ['getArenaActivitiesName']),
    ...mapState('admin', [
      'recurrentEvents',
      'addRecurrentValidateStatus',
    ]),
    ...mapGetters('admin', [
      'getArenaRecurrents',
      'getGeneralRecurrents',
    ]),
    ...mapState('booking', {
      bookingState: 'booking',
    }),

    typeOptions() {
      return this.activities.slice().map((item) => item.activityName);
    },
    recurrentTimeFromHourRange() {
      return this.addRecurrentInputForm.type.value === 'News' ? [[0, 24]] : [[7, 22]];
    },
    recurrentTimeToHourRange() {
      return this.addRecurrentInputForm.type.value === 'News' ? [[this.timeFromHour, 24]] : [[this.timeFromHour, 22]];
    },
    recurrentTimeToMinuteInterval() {
      if (this.addRecurrentInputForm.type.value === 'News') {
        return '';
      } else if (this.addRecurrentInputForm.type.value === 'Adventure Golf') {
        if (this.addRecurrentInputForm.timeFrom.value === 21) {
          return 15;
        } else {
          return 15;
        }
      } else if (this.addRecurrentInputForm.type.value === 'Golf Room') {
        return '';
      }
      return 30;
    },
    recurrentTimeToMinuteRange() {
      if (this.addRecurrentInputForm.timeFrom.value.split(':')[0] === '21'
                && this.addRecurrentInputForm.type.value === 'Adventure Golf'
      ) {
        return [[0, 30]];
      }
      if (this.addRecurrentInputForm.type.value === 'Golf Room') {
        return [0, 25, 30, 55];
      }
      return null;
    },
    timeFromHour() {
      if (!this.addRecurrentInputForm['timeFrom'].value.split(':')[0]) {
        return 7;
      }
      return parseInt(this.addRecurrentInputForm['timeFrom'].value.split(':')[0], 10);
    },
    isAddRecurrentFormError() {
      return Object.values(this.isErrorAddRecurrentForm).some((val) => val === false);
    },
    minDateTo() {
      if (!this.addRecurrentInputForm['dateFrom'].value) {
        return null;
      }
      const splitDates = this.addRecurrentInputForm['dateFrom'].value.split('-');
      const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
      return Date.parse(dateParseFormat).addDays(1).toString('yyyy-MM-dd');
    },
    isTimeFromIncomplete() {
      if (!/^([0-9][0-9]:[0-9][0-9])$/.test(this.addRecurrentInputForm.timeFrom.value) || this.addRecurrentInputForm.timeFrom.value === '') {
        return true;
      }

      return false;
    },
    recurringDayOptionsOnlyText() {
      return this.recurringDayOptions.slice().map((item) => item.text);
    },
  },
  created() {
    this.$store.dispatch('base/getAllActivities');
    this.loadRecurrent();
  },
  methods: {
    async loadRecurrent() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('admin/showRecurrentEvents');
        this.items = this.recurrentEvents;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    onEdit(item) {
      this.resetAddRecurrentForm();
      this.editId = item.discriminator;

      Object.keys(this.addRecurrentInputForm).forEach((itemKey) => {
        this.addRecurrentInputForm = {
          ...this.addRecurrentInputForm,
          [itemKey]: {
            ...this.addRecurrentInputForm[itemKey],
            value: item[itemKey],
          },
        };
      });

      this.addRecurrentInputForm = {
        ...this.addRecurrentInputForm,
        ['recurringDays']: {
          ...this.addRecurrentInputForm['recurringDays'],
          value: item['weekNames'].split(',').map((item) => parseInt(item)),
        },
        ['type']: {
          ...this.addRecurrentInputForm['type'],
          value: item['activityType'],
        },
      };

      if (this.activities.length !== 0) {
        this.$bvModal.show('admin-edit-recurrent-modal');
        return;
      }
      loader.start();
      this.$store.dispatch('base/getAllActivities').then(() => {
        loader.stop();
        this.$bvModal.show('admin-edit-recurrent-modal');
      });
    },
    onDelete(id) {
      this.$bvModal.msgBoxConfirm('Bist Du sicher? Das Löschen kann nicht rückgängig gemacht werden.', {
        title: 'Wiederkehrenden Termin löschen',
        okVariant: 'tab-orange',
        headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        footerClass: 'p-2 border-top-0',
        centered: true,
        cancelTitle: 'Abbrechen',
      })
        .then(async (val) => {
          if (!val) {
            return;
          }
          loader.start();
          try {
            await this.$store.dispatch('admin/removeRecurringEvent', {
              discriminator: id,
            });
            loader.stop();
            this.items = this.recurrentEvents;
            this.$bvModal.msgBoxOk('Booking has been deleted successfully', {
              title: 'Delete successful',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          } catch (err) {
            loader.stop();
            this.$bvModal.msgBoxOk(err, {
              title: 'Delete Error',
              okVariant: 'tab-orange',
              headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
              footerClass: 'p-2 border-top-0',
              centered: true,
            });
          }
        });
    },
    onCreateRecurrent() {
      this.resetAddRecurrentForm();
      if (this.activities.length !== 0) {
        this.$bvModal.show('admin-add-recurrent-modal');
        return;
      }
      loader.start();
      this.$store.dispatch('base/getAllActivities').then(() => {
        loader.stop();
        this.$bvModal.show('admin-add-recurrent-modal');
      });
    },
    validateAddRecurrent() {
      Object.values(this.addRecurrentInputForm).forEach((item) => {
        if (item.required && item.value === '') {
          this.isErrorAddRecurrentForm = {
            ...this.isErrorAddRecurrentForm,
            [item.key]: false,
          };
          this.errorMsgAddRecurrentForm = {
            ...this.errorMsgAddRecurrentForm,
            [item.key]: 'Dieses Feld wird benötigt',
          };
        } else if (item.type === 'time') {
          const todaySlashFormat = Date.today().toString('dd/MM/yyyy');
          const isArenaType = this.getArenaActivitiesName.includes(this.addRecurrentInputForm.type.value);

          if (!/^([0-9][0-9]:[0-9][0-9])$/.test(item.value)) {
            this.isErrorAddRecurrentForm = {
              ...this.isErrorAddRecurrentForm,
              [item.key]: false,
            };
            this.errorMsgAddRecurrentForm = {
              ...this.errorMsgAddRecurrentForm,
              [item.key]: 'Bitte geben Sie die Endzeit ein',
            };
          } else if (this.addRecurrentInputForm.timeFrom.value === this.addRecurrentInputForm.timeTo.value) {
            this.isErrorAddRecurrentForm = {
              ...this.isErrorAddRecurrentForm,
              ['timeTo']: false,
            };
            this.errorMsgAddRecurrentForm = {
              ...this.errorMsgAddRecurrentForm,
              ['timeTo']: 'Bitte buchen Sie mindestens 30 Minuten',
            };
          } else if (Date.parse(`${todaySlashFormat} ${this.addRecurrentInputForm.timeTo.value}:00`) < Date.parse(`${todaySlashFormat} ${this.addRecurrentInputForm.timeFrom.value}:00`)) {
            this.isErrorAddRecurrentForm = {
              ...this.isErrorAddRecurrentForm,
              ['timeFrom']: false,
            };
            this.errorMsgAddRecurrentForm = {
              ...this.errorMsgAddRecurrentForm,
              ['timeFrom']: 'Bitte wählen Sie die Zeit von vor Zeit bis.',
            };
          } else if (this.addRecurrentInputForm.timeFrom.value === '07:00' && isArenaType) {
            this.isErrorAddRecurrentForm = {
              ...this.isErrorAddRecurrentForm,
              ['timeFrom']: false,
            };
            this.errorMsgAddRecurrentForm = {
              ...this.errorMsgAddRecurrentForm,
              ['timeFrom']: 'Bitte wählen Sie die Zeit nach 07.30 Uhr.',
            };
          }
        } else if (item.value !== '' && item.type === 'email' && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(item.value))) {
          this.isErrorAddRecurrentForm = {
            ...this.isErrorAddRecurrentForm,
            [item.key]: false,
          };
          this.errorMsgAddRecurrentForm = {
            ...this.errorMsgAddRecurrentForm,
            [item.key]: 'Sie haben eine ungültige Email-Adresse eingegeben!',
          };
        } else if (item.type === 'selectGroup' && item.value.length === 0) {
          this.isErrorAddRecurrentForm = {
            ...this.isErrorAddRecurrentForm,
            [item.key]: false,
          };
          this.errorMsgAddRecurrentForm = {
            ...this.errorMsgAddRecurrentForm,
            [item.key]: 'Dieses Feld wird benötigt',
          };
        } else {
          this.isErrorAddRecurrentForm = {
            ...this.isErrorAddRecurrentForm,
            [item.key]: null,
          };
          this.errorMsgAddRecurrentForm = {
            ...this.errorMsgAddRecurrentForm,
            [item.key]: '',
          };
        }
      });
    },
    async onSubmitAddRecurrent() {
      this.validateAddRecurrent();

      if (this.isAddRecurrentFormError) {
        return;
      }

      loader.start();
      const isArenaType = this.getArenaActivitiesName.includes(this.addRecurrentInputForm.type.value);
      const selectType = isArenaType ? 'Arena' : this.addRecurrentInputForm.type.value;

      await this.$store.dispatch('booking/showBookings', {
        dateFrom: this.addRecurrentInputForm.dateFrom.value,
        dateTo: this.addRecurrentInputForm.dateTo.value,
        type: selectType,
      });
      const duplicateTimeEvents = this.bookingState[`${selectType}Events`].filter((item) => {
        // Check for same selcted days value
        const splitDates = item.bookingDay.split('-');
        const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
        const getIndexOfDuplicateDate = this.recurringDayOptionsOnlyText.indexOf(Date.parse(dateParseFormat).toString('dddd'));
        const isDuplicateDay = this.addRecurrentInputForm.recurringDays.value.includes(this.recurringDayOptions[getIndexOfDuplicateDate].value);

        if (isDuplicateDay) {
          // Check for overlapping times
          const isSameSelectedTimeFromAndItemTimeTo = `${this.addRecurrentInputForm.timeFrom.value}:00` === item.timeTo;
          if (isSameSelectedTimeFromAndItemTimeTo) {
            return false;
          } else {
            let isOverlappedTime = false;
            if (Date.parse(`${item.bookingDay}T${item.timeFrom}`) > Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`) &&
                        Date.parse(`${item.bookingDay}T${item.timeTo}`) < Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)
            ) {
              isOverlappedTime =
                        Date.parse(`${item.bookingDay}T${item.timeFrom}`)
                          .between(Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)) ||
                        Date.parse(`${item.bookingDay}T${item.timeTo}`)
                          .between(Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`));
            } else {
              isOverlappedTime =
                        Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`)
                          .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`)) ||
                        Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)
                          .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`));
            }
            return isOverlappedTime;
          }
        } else {
          return false;
        }
      });

      if (duplicateTimeEvents.length === 0) {
        this.submitAddRecurrent();
        return;
      }

      loader.stop();
      this.duplicateEvents = duplicateTimeEvents.map((item) => {
        return {
          ...item,
          timeTo: item.timeTo.slice(0, -3),
          timeFrom: item.timeFrom.slice(0, -3),
        };
      });
      this.$bvModal.show('admin-duplicate-event-modal');
    },
    async onSubmitEditRecurrent() {
      this.validateAddRecurrent();

      if (this.isAddRecurrentFormError) {
        return;
      }

      loader.start();
      const isArenaType = this.getArenaActivitiesName.includes(this.addRecurrentInputForm.type.value);
      const selectType = isArenaType ? 'Arena' : this.addRecurrentInputForm.type.value;

      await this.$store.dispatch('booking/showBookings', {
        dateFrom: this.addRecurrentInputForm.dateFrom.value,
        dateTo: this.addRecurrentInputForm.dateTo.value,
        type: selectType,
      });

      const duplicateTimeEvents = this.bookingState[`${selectType}Events`].filter((item) => {
        // Check for same selcted days value
        const splitDates = item.bookingDay.split('-');
        const dateParseFormat = `${splitDates[0]}/${parseInt(splitDates[2])}/${parseInt(splitDates[1])}`; // month/day/year - 7/1/2004
        const getIndexOfDuplicateDate = this.recurringDayOptionsOnlyText.indexOf(Date.parse(dateParseFormat).toString('dddd'));
        const isDuplicateDay = this.addRecurrentInputForm.recurringDays.value.includes(this.recurringDayOptions[getIndexOfDuplicateDate].value);
        const isSameTime = `${this.addRecurrentInputForm.timeTo.value}:00` === item.timeTo && `${this.addRecurrentInputForm.timeFrom.value}:00` === item.timeFrom;
        const isSameDayAndTime = isDuplicateDay && isSameTime;

        if (isSameDayAndTime) {
          return false;
        } else if (isDuplicateDay) {
          // Check for overlapping time
          const isSameSelectedTimeFromAndItemTimeTo = `${this.addRecurrentInputForm.timeFrom.value}:00` === item.timeTo;
          if (isSameSelectedTimeFromAndItemTimeTo) {
            return false;
          } else {
            let isOverlappedTime = false;
            if (Date.parse(`${item.bookingDay}T${item.timeFrom}`) > Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`) &&
                            Date.parse(`${item.bookingDay}T${item.timeTo}`) < Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)
            ) {
              isOverlappedTime =
                            Date.parse(`${item.bookingDay}T${item.timeFrom}`)
                              .between(Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)) ||
                            Date.parse(`${item.bookingDay}T${item.timeTo}`)
                              .between(Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`), Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`));
            } else {
              isOverlappedTime =
                            Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeFrom.value}:00`)
                              .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`)) ||
                            Date.parse(`${item.bookingDay}T${this.addRecurrentInputForm.timeTo.value}:00`)
                              .between(Date.parse(`${item.bookingDay}T${item.timeFrom}`), Date.parse(`${item.bookingDay}T${item.timeTo}`));
            }
            return isOverlappedTime;
          }

        } else {
          return false;
        }
      });

      if (duplicateTimeEvents.length === 0) {
        this.submitEditRecurrent();
        return;
      }

      loader.stop();
      this.duplicateEvents = duplicateTimeEvents.map((item) => {
        return {
          ...item,
          timeTo: item.timeTo.slice(0, -3),
          timeFrom: item.timeFrom.slice(0, -3),
        };
      });
      this.$bvModal.show('admin-duplicate-event-modal');
    },
    onSubmitAfterHandleDuplicate() {
      loader.start();
      this.duplicateEvents = [];

      if (this.editId === 0) {
        this.submitAddRecurrent();
      } else {
        this.submitEditRecurrent();
      }
    },
    async submitAddRecurrent() {
      if (this.replaceEvents.length !== 0) {
        try {
          await AdminInformation.cancelBooking({
            identification: this.replaceEvents.join(),
            eliminator: 'p',
          });
          this.replaceEvents = [];
        } catch (err) {
          loader.stop();
          this.$bvModal.msgBoxOk(err, {
            title: 'Replace event error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
      }

      const payload = {
        weekNumber: this.addRecurrentInputForm.recurringDays.value.join(','),
        type: this.addRecurrentInputForm.type.value,
        timeFrom: `${this.addRecurrentInputForm.timeFrom.value}:00`,
        timeTo: `${this.addRecurrentInputForm.timeTo.value}:00`,
        dateFrom: this.addRecurrentInputForm.dateFrom.value,
        dateTo: this.addRecurrentInputForm.dateTo.value,
        information: this.addRecurrentInputForm.information.value,
        name: this.addRecurrentInputForm.name.value,
        company: this.addRecurrentInputForm.company.value,
        telephone: this.addRecurrentInputForm.telephone.value,
        email: this.addRecurrentInputForm.email.value,
      };
      try {
        await AdminInformation.addRecurrentEvents(payload);
        loader.stop();
        this.$bvModal.msgBoxOk('Add Recurrent successful', {
          title: 'Add Recurrent',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.loadRecurrent();
          this.$bvModal.hide('admin-duplicate-event-modal');
          this.$bvModal.hide('admin-add-recurrent-modal');
          this.resetAddRecurrentForm();
        });
      } catch (err) {
        loader.stop();
        this.duplicateEventErrorMsg = err.response.data;
        // const h = this.$createElement;

        // const detailMsgList = err.response.data.details.map((msg) => {
        //     return h('div', [
        //         '- ',
        //         msg,
        //     ]);
        // });

        // const messageVNode = h('div', [
        //     h('p', { class: ['text-bold', 'mb-2'] }, [`${response.data.information}`]),
        //     h('div', detailMsgList),
        // ]);

        // this.$bvModal.msgBoxOk(messageVNode, {
        //     title: 'Add Recurrent Error',
        //     okVariant: 'tab-orange',
        //     headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        //     footerClass: 'p-2 border-top-0',
        //     centered: true
        // });
        this.$bvModal.show('admin-duplicate-event-readonly-modal');
      }
    },
    async submitEditRecurrent() {
      if (this.replaceEvents.length !== 0) {
        try {
          await AdminInformation.cancelBooking({
            identification: this.replaceEvents.join(),
            eliminator: 'p',
          });
          this.replaceEvents = [];
        } catch (err) {
          loader.stop();
          this.$bvModal.msgBoxOk(err, {
            title: 'Replace event error',
            okVariant: 'tab-orange',
            headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
          return;
        }
      }

      const payload = {
        discriminator: this.editId,
        weekNumber: this.addRecurrentInputForm.recurringDays.value.join(','),
        type: this.addRecurrentInputForm.type.value,
        timeFrom: `${this.addRecurrentInputForm.timeFrom.value}:00`,
        timeTo: `${this.addRecurrentInputForm.timeTo.value}:00`,
        dateFrom: this.addRecurrentInputForm.dateFrom.value,
        dateTo: this.addRecurrentInputForm.dateTo.value,
        information: this.addRecurrentInputForm.information.value,
        name: this.addRecurrentInputForm.name.value,
        company: this.addRecurrentInputForm.company.value,
        telephone: this.addRecurrentInputForm.telephone.value,
        email: this.addRecurrentInputForm.email.value,
      };

      try {
        await this.$store.dispatch('admin/alterRecurringEvent', payload);
        this.items = this.recurrentEvents;
        loader.stop();
        this.$bvModal.msgBoxOk('Edit successful', {
          title: 'Edit recurrent',
          okVariant: 'tab-orange',
          headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
          footerClass: 'p-2 border-top-0',
          centered: true,
        }).then(() => {
          this.$bvModal.hide('admin-edit-recurrent-modal');
          this.$bvModal.hide('admin-duplicate-event-modal');
          this.resetAddRecurrentForm();
        });
      } catch (err) {
        loader.stop();
        this.duplicateEventErrorMsg = err.response.data;
        this.$bvModal.show('admin-duplicate-event-readonly-modal');
        // const h = this.$createElement;

        // const detailMsgList = err.details.map((msg) => {
        //     return h('div', [
        //         '- ',
        //         msg,
        //     ]);
        // });

        // const messageVNode = h('div', [
        //     h('p', { class: ['text-bold', 'mb-2'] }, [`${err.information}`]),
        //     h('div', detailMsgList),
        // ]);

        // this.$bvModal.msgBoxOk(messageVNode, {
        //     title: 'Edit Error',
        //     okVariant: 'tab-orange',
        //     headerClass: 'p-2 py-3 border-bottom-0 text-capitalize',
        //     footerClass: 'p-2 border-top-0',
        //     centered: true
        // });
      }
    },
    onDateFromInput() {
      this.isErrorAddRecurrentForm[this.addRecurrentInputForm.dateTo.key] = null;
      this.isErrorAddRecurrentForm[this.addRecurrentInputForm.dateFrom.key] = null;
      this.addRecurrentInputForm.dateTo.value = '';
    },
    onCancelDuplicateEvent() {
      this.replaceEvents = [];
    },
    resetAddRecurrentForm() {
      this.editId = 0;
      this.is24Hour = false;
      Object.values(this.addRecurrentInputForm).forEach((item) => {
        if (item.type === 'selectGroup') {
          this.addRecurrentInputForm = {
            ...this.addRecurrentInputForm,
            [item.key]: {
              ...item,
              value: [],
            },
          };
        } else {
          this.addRecurrentInputForm = {
            ...this.addRecurrentInputForm,
            [item.key]: {
              ...item,
              value: '',
            },
          };
        }
      });
      Object.keys(this.isErrorAddRecurrentForm).forEach((key) => {
        this.isErrorAddRecurrentForm = {
          ...this.isErrorAddRecurrentForm,
          [key]: this.isErrorAddRecurrentForm[key],
        };
      });
    },
    onReplaceAll() {
      this.replaceEvents = this.duplicateEvents.slice().map((item) => item.identification);
      this.duplicateEvents = [];
    },
    onSkipAll() {
      this.duplicateEvents = [];
    },
    handleLeave(event) {
      this.duplicateEvents = this.duplicateEvents.filter((item) => item.identification !== event.identification);
    },
    handleReplace(event) {
      this.replaceEvents.push(event.identification);
      this.duplicateEvents = this.duplicateEvents.filter((item) => item.identification !== event.identification);
    },
    async onClickDetail(item) {
      if (item.details) {
        this.selectRecurrentDetail = item;
        this.$bvModal.show('admin-recurrent-detail-modal');
        return;
      }
      loader.start();
      try {
        await this.$store.dispatch('admin/showRecurringEventsDetails', {
          discriminator: item.discriminator,
        });
        this.items = this.recurrentEvents;
        this.selectRecurrentDetail = this.recurrentEvents.find((stateItem) => stateItem.discriminator === item.discriminator);
        this.$bvModal.show('admin-recurrent-detail-modal');
        loader.stop();
      } catch {
        loader.stop();
      }
    },
    allowedDatesRecurrentDetail(val) {
      return this.selectRecurrentDetail.details.includes(val);
    },
    recurrentFilterTable(row, data) {
      if (row.activityType.toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.name.toLowerCase() === data.toLowerCase()) {
        return true;
      } else if (row.dateFrom.includes(data)) {
        return true;
      } else if (row.dateTo.includes(data)) {
        return true;
      } else if (row.timeFrom.includes(data)) {
        return true;
      } else if (row.timeTo.includes(data)) {
        return true;
      } else if (germanDateForSearch(row.dateFrom).includes(data.toLowerCase())
                || germanDate(row.dateFrom).toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (germanDateForSearch(row.dateTo).includes(data.toLowerCase())
                || germanDate(row.dateTo).toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.information.toLowerCase().includes(data.toLowerCase())) {
        return true;
      } else if (row.weekNames) {
        const weekdays = row.weekNames.split(',');
        const weekdayNames = weekdays.map((itemIndex) => this.recurringDayOptionsOnlyText[parseInt(itemIndex)].toLowerCase());
        return weekdayNames.join(' ').includes(data.toLowerCase());
      }
    },
    onClick24Hour() {
      if (this.is24Hour) {
        this.addRecurrentInputForm.timeFrom.value = '00:00';
        this.addRecurrentInputForm.timeTo.value = '23:59';
      } else {
        this.addRecurrentInputForm.timeFrom.value = '';
        this.addRecurrentInputForm.timeTo.value = '';
        this.$refs.timeFromRef.clearTime();
        this.$refs.timeToRef.clearTime();
      }
    },
  },
};
</script>